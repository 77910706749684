import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TextInput from '../atoms/TextInput';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

export default function LoginModal({ isOpen, onClose }) {
  const router = useRouter();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setUsername('');
    setPassword('');
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    //window.open('https://www.ehyp.de/ehyp/servlet/ehyp?view=brokerLogin', '_blank').focus();

    const result = await signIn('credentials', {
      redirect: false,
      username,
      password
    });

    if (result.status === 200 && !result.error) {
      onClose();
      router.push('/dashboard');
    } else {
      setError(result.error);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <form
        name="b2bLogin"
        onSubmit={handleSubmit}
      >
        <DialogTitle>
          Partner-Login
        </DialogTitle>
        <DialogContent>
          {error && (
            <Box mb={1}>
              <Alert severity="error">
                {error === 'CredentialsSignin'
                  ? 'Benutzer oder Passwort nicht gefunden.'
                  : error}
              </Alert>
            </Box>
          )}
          <Box mb={3}>
            <TextInput
              fullWidth
              name="login"
              label="Benutzername"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
            <TextInput
              fullWidth
              name="pwd"
              type="password"
              label="Passwort"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <input name="do" value="brokerLogin" type="hidden"/>
            <input name="dontRedirect" value="" type="hidden"/>
            <input name="area" value="workplace" type="hidden"/>
          </Box>
        </DialogContent>
        <DialogActions style={{ overflow: 'hidden' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={onClose}
              >
                Abbrechen
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                disabled={!username || !password}
                type="submit"
                color="primary"
                variant="contained"
              >
                Anmelden
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}
