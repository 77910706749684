import {
  Box,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import Link from '../../atoms/Link';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { navigationItemShape } from './propTypes';

export default function DesktopMenuItemList(props) {
  const { items, onClick } = props;
  const classes = useStyles();

  return (
    <List
      className={classes.root}
    >
      {items.map(item => (
        <Item
          key={item.path}
          item={item}
          level={1}
          classes={classes}
          onClick={onClick}
        />
      ))}
    </List>
  );
}

DesktopMenuItemList.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func
};
DesktopMenuItemList.defaultProps = {
};

function Item(props) {
  const { item, level, classes, onClick } = props;
  const hasItems = item.items?.length > 0;

  return (
    <>
      <Link href={item.path}>
        <ListItem
          button
          component="a"
          onClick={onClick}
        >
          <ListItemText
            primaryTypographyProps={{
              className: clsx({})
            }}
          >
            {item.title}
          </ListItemText>
        </ListItem>
      </Link>
      {hasItems && (
        <Box pl={4} mb={3}>
          <List component="div" disablePadding>
            {item.items.map(subItem => (
              <Item
                key={subItem.path}
                item={subItem}
                level={level + 1}
                classes={classes}
                onClick={onClick}
              />
            ))}
          </List>
        </Box>
      )}
    </>
  );
}

Item.propTypes = {
  item: navigationItemShape,
  level: PropTypes.number,
  classes: PropTypes.object.isRequired,
  // Events
  onClick: PropTypes.func
};

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 300
  },
}));
