import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
  FiChevronDown,
} from 'react-icons/fi';
import Link from '../../atoms/Link';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { navigationItemShape } from './propTypes';
import trim from 'lodash/trim';

export default function MobileMenuItem(props) {
  const { item, level, onClick } = props;
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);
  const hasItems = item.items?.length > 0;

  const handleToggleItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setExpanded(!isExpanded);
  };

  return (
    <>
      <Link href={item.path}>
        <ListItem
          button
          component="a"
          className={classes.root}
          onClick={onClick}
        >
          <ListItemText
            primaryTypographyProps={{
              className: clsx({
                [classes.menuItemLevel1]: level === 1
              })
            }}
          >
            {trim(item.title)}
          </ListItemText>
          {hasItems && (
            <IconButton onClick={handleToggleItem}>
              <FiChevronDown className={clsx(classes.menuItemIcon, {
                [classes.menuItemIconExpanded]: isExpanded
              })} />
            </IconButton>
          )}
        </ListItem>
      </Link>
      {hasItems && (
        <Collapse in={isExpanded} timeout="auto">
          <Box pl={4} mb={3}>
            <List component="div" disablePadding>
              {item.items.map(subItem => (
                <MobileMenuItem
                  key={subItem.path}
                  item={subItem}
                  level={level + 1}
                  onClick={onClick}
                />
              ))}
            </List>
          </Box>
        </Collapse>
      )}
    </>
  );
}

MobileMenuItem.propTypes = {
  item: navigationItemShape.isRequired,
  level: PropTypes.number.isRequired,
  // Events
  onClick: PropTypes.func
};
MobileMenuItem.defaultProps = {
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  menuItemLevel1: {
    fontWeight: '700'
  },
  menuItemIcon: {
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.complex,
    })
  },
  menuItemIconExpanded: {
    transform: 'rotate(180deg)'
  },
}));
