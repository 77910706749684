import * as yup from 'yup';

import PropTypes from 'prop-types';

const registeredComponents = [];

export function ContentBlockRenderer(props) {
  const { id, data } = props;
  const { blocks } = data;

  return blocks.map(blockItem => {
    const componentDefinition = registeredComponents
      .find(component => component.__typename === blockItem.__typename);

    if(!componentDefinition){
      throw new Error(`Component of type '${blockItem.__typename}' not found.\nRegister component in "cmsconfig.js" file.`);
    }

    const { component: Component } = componentDefinition;
    return (
      <Component
        key={`${id}_${blockItem.__typename}_${blockItem.id}`}
        data={blockItem}
        options={componentDefinition.options}
        blocks={blocks}
      />
    );
  });
}

ContentBlockRenderer.propTypes = {
  data: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any.isRequired,
      __typename: PropTypes.string.isRequired,
    }))
  }).isRequired
};

ContentBlockRenderer.registerComponent = function(name, component, options) {
  const componentDefinition = {
    __typename: name,
    component,
    options
  };

  registerComponentArgsSchema.validateSync(componentDefinition);
  registeredComponents.push(componentDefinition);
};

const registerComponentArgsSchema = yup.object({
  __typename: yup.string().min(3).required(),
  component: yup.mixed().required()
});
