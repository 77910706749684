import { Fab, Hidden, IconButton } from '@material-ui/core';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { zIndices } from '../../theme';

export default function WhatsappFab(props) {
  const { phone, iconButton } = props;
  const classes = useStyles();
  const phoneNo = (phone ?? '').replace('+', '');

  if (iconButton) {
    return (
      <IconButton
        component="a"
        href={`https://wa.me/${phoneNo}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Whatsapp"
      >
        <WhatsappIcon classes={classes} iconButton />
      </IconButton>
    );
  }

  return (
    <Hidden mdDown>
      <div className={classes.root}>
        <Fab
          component="a"
          className={classes.button}
          href={`https://wa.me/${phoneNo}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Whatsapp"
        >
          <WhatsappIcon classes={classes} />
        </Fab>
      </div>
    </Hidden>
  );
}

WhatsappFab.propTypes = {
  phone: PropTypes.string,
  iconButton: PropTypes.bool
};
WhatsappFab.defaultProps = {
  phone: '',
  iconButton: false
};

export function WhatsappIcon({ classes, iconButton }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 425 425"
      className={iconButton ? classes.iconOnly : classes.icon}
    >
      <g>
        <path
          className={iconButton ? classes.iconPath2 : classes.iconPath}
          d="M361,62.38A207.17,207.17,0,0,0,213.47,1.22C98.52,1.22,5,94.77,4.92,209.76A208.16,208.16,0,0,0,32.76,314L3.17,422.09l110.56-29a208.28,208.28,0,0,0,99.66,25.38h.09c114.94,0,208.5-93.56,208.55-208.55A207.29,207.29,0,0,0,361,62.38ZM213.48,383.25h-.07a173.08,173.08,0,0,1-88.23-24.16l-6.33-3.76L53.24,372.54l17.51-64L66.63,302a173,173,0,0,1-26.5-92.25c0-95.57,77.8-173.33,173.42-173.33A173.37,173.37,0,0,1,386.82,209.91C386.78,305.49,309,383.25,213.48,383.25Zm95.08-129.82c-5.21-2.61-30.83-15.21-35.61-17s-8.25-2.6-11.72,2.61-13.46,17-16.5,20.44-6.08,3.91-11.29,1.3-22-8.11-41.91-25.87c-15.49-13.81-25.95-30.88-29-36.09s-.32-8,2.28-10.64c2.35-2.34,5.22-6.09,7.82-9.13s3.48-5.22,5.21-8.69.87-6.52-.43-9.13-11.73-28.26-16.07-38.7c-4.23-10.16-8.52-8.78-11.72-8.94-3-.15-6.52-.18-10-.18a19.15,19.15,0,0,0-13.9,6.52c-4.77,5.21-18.24,17.82-18.24,43.47s18.68,50.43,21.28,53.91,36.75,56.11,89,78.68a299.53,299.53,0,0,0,29.71,11c12.48,4,23.84,3.41,32.82,2.06,10-1.49,30.83-12.6,35.18-24.77s4.34-22.61,3-24.78S313.77,256,308.56,253.43Z"
        />
      </g>
    </svg>
  );
}

WhatsappIcon.propTypes = {
  classes: PropTypes.object,
  iconButton: PropTypes.bool
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    left: '3%',
    bottom: 80,
    zIndex: zIndices.whatsappFab
  },
  button: {
    backgroundColor: '#25d366',
    '&:hover, &:focus': {
      backgroundColor: '#33e576'
    }
  },
  icon: {
    width: 36,
    height: 36,
    marginRight: 1,
    marginTop: 1
  },
  iconOnly: {
    width: 24,
    height: 24,
  },
  iconPath: {
    fill: 'white',
    fillRule: 'evenodd',
  },
  iconPath2: {
    fill: '#25d366',
    fillRule: 'evenodd',
  }
}));
