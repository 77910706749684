import React, { Suspense, lazy } from 'react';

import {
  Alert
} from '@material-ui/lab';
import PropTypes from 'prop-types';
import ProvenExpertContext from '../../context/ProvenExpertContext';
import WidgetContext from '../../context/WidgetContext';
import { isClientSide } from '../../lib/utils/environment';

const Tracking = lazy(() => import('../organisms/Tracking'));

export default function WebsiteContainer({ children, pageProps }) {
  const {
    widget,
    provenExpertGoogleStars
  } = pageProps;

  if (!widget) {
    return (
      <div>
        <Alert severity="error">
          Leider kann dieses Widget gerade nicht angezeigt werden. Bitte versuchen Sie es später erneut.
        </Alert>
        <GlobalStyles />
      </div>
    );
  }

  return (
    <WidgetContext.Provider value={widget}>
      <ProvenExpertContext.Provider value={provenExpertGoogleStars}>
        {children}
        {isClientSide() && (
          <>
            <Suspense fallback={<div />}>
              <Tracking />
            </Suspense>
          </>
        )}
        <GlobalStyles />
      </ProvenExpertContext.Provider>
    </WidgetContext.Provider>
  );
}

WebsiteContainer.propTypes = {
  children: PropTypes.element.isRequired,
  pageProps: PropTypes.object
};

function GlobalStyles() {
  return (
    <style global jsx>{`
        body {
          background: none transparent !important;
        }

        #__next {
          min-height: 0 !important;
        }
    `}</style>
  );
}
