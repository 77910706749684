import * as yup from 'yup';

import { updateDomAsync, useConsent } from '@lib/gdpr';
import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import useContentElement from '../../hooks/useContentElement';

export default function EmbedElement(props) {
  const { data, options, ...otherProps } = props;
  const frameRef = useRef();
  const hasConsent = useConsent('youtube');

  useEffect(() => {
    updateDomAsync();
  }, []);

  const { elementData } = useContentElement(
    data,
    EmbedElement.dataSchema
  );

  const { provider, url } = elementData;
  let videoId = null;

  switch (provider) {
    case 'youtube':
      if (url.includes('v=')) {
        videoId = url.split('v=')[1];
        const ampersandPosition = videoId.indexOf('&');

        if (ampersandPosition != -1) {
          videoId = videoId.substring(0, ampersandPosition);
        }
      } else if (url.includes('youtu.be/')) {
        videoId = url.split('.be/')[1];
      }
      break;
    default:
      break;
  }

  return hasConsent
    ? (
      <YouTube
        videoId={videoId}
        opts={options}
        {...otherProps}
      />
    )
    : (
      <div>
        <iframe
          ref={frameRef}
          data-name={provider}
          data-src="about:blank"
          className={otherProps.className}
          style={{ border: 0 }}
        />
      </div>
    );
}

const PROVIDER = [
  'youtube'
];

EmbedElement.typeName = 'EmbedElement'; // Strapi element type
EmbedElement.propTypes = {
  data: PropTypes.shape({
    provider: PropTypes.oneOf(PROVIDER).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};
EmbedElement.dataSchema = yup.object().shape({
  provider: yup.string().oneOf(PROVIDER),
  url: yup.string(),
});
EmbedElement.graphQlSchema = `
id
provider
url
`;
