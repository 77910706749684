import {
  IconButton,
  ListItem,
  ListItemText,
  Popover,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { easings, timings, zIndices } from '../../../theme';

import DesktopMenuItemList from './DesktopMenuItemList';
import DesktopMenuItemMegaMenu from './DesktopMenuItemMegaMenu';
import {
  FiChevronDown,
} from 'react-icons/fi';
import Link from '../../atoms/Link';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import getConfig from 'next/config';
import { makeStyles } from '@material-ui/styles';
import { navigationItemShape } from './propTypes';

const { publicRuntimeConfig } = getConfig();

const POPOVER_CLOSE_TIMEOUT = 350;
const DEV_FORCE_MEGA_MENU_OPEN = false;

export default function DesktopMenuItem(props) {
  const { item, onClick } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mouseState] = useState({
    isMouseOver: false
  });
  const [closeTimeout, setCloseTimeout] = useState(null);
  const targetEl = useRef();

  const hasItems = item.items.length > 0 || Boolean(item.megaMenu);
  const hasPopover = hasItems;
  const isPopoverVisible = Boolean(anchorEl);

  useEffect(() => {
    if (publicRuntimeConfig.isDev && item.megaMenu && DEV_FORCE_MEGA_MENU_OPEN) {
      handleTogglePopover({
        preventDefault: () => { },
        stopPropagation: () => { },
      });
    }

    return () => {
      clearTimeout(closeTimeout);
    };
  }, []);

  const handlePopoverOpen = (event) => {
    clearTimeout(closeTimeout);

    if (!hasPopover) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (e) => {
    if (!hasPopover) {
      return;
    }

    handlePopoverMouseOut();
    setCloseTimeout(setTimeout(() => {
      if (mouseState.isMouseOver) {
        return;
      }

      setAnchorEl(null);
      onClick(e);
    }, POPOVER_CLOSE_TIMEOUT));
  };
  const handleTogglePopover = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!hasPopover) {
      return;
    }

    if (anchorEl) {
      handlePopoverClose(e);
    } else {
      setAnchorEl(targetEl.current);
    }
  };
  const handlePopoverMouseOver = () => {
    clearTimeout(closeTimeout);
    mouseState.isMouseOver = true;
  };
  const handlePopoverMouseOut = () => {
    mouseState.isMouseOver = false;
  };

  return (
    <>
      <Link
        key={item.path}
        href={item.path}
      >
        <ListItem
          ref={targetEl}
          button
          component="a"
          className={clsx(
            `${item.title.toLowerCase().replace(/\s/g, '')}_tag`,
            classes.root,
            {
              [classes.menuItemPopoverVisible]: isPopoverVisible
            }
          )}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          onClick={handlePopoverClose}
        >
          <ListItemText
            primaryTypographyProps={{
              variant: 'subtitle2',
              className: classes.menuItemLevel1
            }}
          >
            {item.title}
          </ListItemText>
          {hasItems && (
            <IconButton size="small" onClick={handleTogglePopover} aria-label="expand">
              <FiChevronDown className={clsx(classes.menuItemIcon, {
                [classes.menuItemIconExpanded]: isPopoverVisible
              })} />
            </IconButton>
          )}
        </ListItem>
      </Link>
      {hasPopover && (
        <Popover
          disableRestoreFocus
          open={isPopoverVisible}
          anchorEl={anchorEl}
          elevation={24}
          className={classes.menuItemPopover}
          classes={{
            paper: classes.menuItemPopoverPaper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            onMouseOver: handlePopoverMouseOver,
            onMouseOut: handlePopoverMouseOut,
            onMouseLeave: handlePopoverClose
          }}
        >
          {item.megaMenu
            ? (
              <DesktopMenuItemMegaMenu
                item={item.megaMenu}
                onClick={handlePopoverClose}
              />
            )
            : (
              <DesktopMenuItemList
                items={item.items}
                onClick={handlePopoverClose}
              />
            )}
        </Popover>
      )}
    </>
  );
}

DesktopMenuItem.propTypes = {
  item: navigationItemShape.isRequired,
  // Events
  onClick: PropTypes.func
};
DesktopMenuItem.defaultProps = {
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.secondary.contrastText
  },
  menuItemIcon: {
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.complex,
    })
  },
  menuItemIconExpanded: {
    transform: 'rotate(180deg)'
  },
  menuItemPopover: {
    pointerEvents: 'none',
    zIndex: `${zIndices.desktopMenuPopover} !important`,
  },
  menuItemPopoverVisible: {
    '&::after': {
      opacity: 1,
      transition: `opacity ${timings.defaultAnimationTiming}ms 100ms ${easings.defaultEasingCss}`,
    }
  },
  menuItemPopoverPaper: {
    pointerEvents: 'auto',
    marginTop: 10,
    display: 'flex',
    '& > div:not(:last-child)': {
      borderRight: '0.1rem solid rgba(0, 0, 0, 0.05)'
    }
  },
  menuItemLevel1: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}));
