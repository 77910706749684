import MuiContainer from '@material-ui/core/Container';
import React from 'react';

export default function Container(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiContainer {...props} />
  );
}

Container.propTypes = {
  ...MuiContainer.propTypes,
};
Container.defaultProps = {
  ...MuiContainer.defaultProps,
};
