import { Button, Grid, Hidden, Typography } from '@material-ui/core';
import { FiMail, FiPhone } from 'react-icons/fi';

import Container from '../atoms/Container';
import Link from '../atoms/Link';
import { makeStyles } from '@material-ui/styles';

export const PageTopBarHeight = 32;

export default function PageTopBar () {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container justify="space-between" alignItems="center" className={classes.container}>
          <Grid item xs={12} sm={'auto'}>
            <Typography component="div" variant="caption" noWrap>
              Offizieller Automobilpartner der{' '}
              <Link href="#">
                <a>
                  <strong>
                    Füchse Berlin
                  </strong>
                </a>
              </Link>
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={'auto'}>
              <div className={classes.actions}>
                <Button
                  color="inherit"
                  size="small"
                  startIcon={<FiPhone />}
                >
                  (030) 98300-2500
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  startIcon={<FiMail />}
                >
                  info@schimmel-automobile.de
                </Button>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.contrastText,
  },
  container: {
    height: PageTopBarHeight
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}));
