import {
  Box,
  Chip,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import {
  FiBatteryCharging,
  FiHelpCircle,
  FiLoader,
  FiPlus,
  FiStar,
  FiSun,
  FiTag,
  FiZap
} from 'react-icons/fi';
import {
  megaMenuGroupShape,
  megaMenuItemShape,
  megaMenuShape,
} from './propTypes';

import Image from '../../atoms/Image';
import { LinkElement } from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

export default function DesktopMenuItemMegaMenu(props) {
  const { item, onClick } = props;
  const classes = useStyles();
  const hasItems = item.items.length > 0;
  const hasGroups = item.groups.length > 0;
  const shouldDivide = hasItems && hasGroups;

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="stretch"
      >
        {hasGroups && (
          <Grid item xs={'auto'}>
            {item.groups.map((group, index) => (
              <Group
                key={group.id}
                item={group}
                classes={classes}
                isLastGroup={index >= item.groups.length - 1}
                onClick={onClick}
              />
            ))}
          </Grid>
        )}
        {shouldDivide && (
          <Hidden>
            <Grid item xs={'auto'}>
              <Divider orientation="vertical" />
            </Grid>
          </Hidden>
        )}
        {hasItems && (
          <Grid item xs={'auto'}>
            <List component="div">
              {item.items.map((item) => (
                <Item
                  key={item.id}
                  item={item}
                  classes={classes}
                  onClick={onClick}
                />
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

DesktopMenuItemMegaMenu.propTypes = {
  item: megaMenuShape,
  // Events
  onClick: PropTypes.func
};
DesktopMenuItemMegaMenu.defaultProps = {
};

function Item(props) {
  const { classes, item, dense, onClick } = props;
  const {
    title,
    badge,
    badge_icon,
    image,
    link
  } = item;

  return (
    <LinkElement data={link}>
      <ListItem
        button
        dense={dense}
        component="a"
        className={clsx(item.css_class, classes.item)}
        onClick={onClick}
      >
        {image && (
          <ListItemAvatar
            className={classes.itemImageContainer}
          >
            <Image
              isFluid
              src={image.formats.thumbnail.url}
              className={classes.itemImage}
            />
          </ListItemAvatar>
        )}
        <ListItemText>
          {title}
        </ListItemText>
        {badge && (
          <Chip
            label={badge}
            size="small"
            color="primary"
            className={classes.itemBadge}
            icon={badge_icon ? mapBadgeIcon(badge_icon) : null}
          />
        )}
      </ListItem>
    </LinkElement>
  );
}

Item.propTypes = {
  item: megaMenuItemShape,
  dense: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  // Events
  onClick: PropTypes.func
};
Item.defaultProps = {
};

function Group(props) {
  const { classes, item, isLastGroup, onClick } = props;
  const {
    title,
    items
  } = item;
  let columnSpan = items.length <= 1 ? 12 : 6;

  return (
    <>
      <Box className={clsx(item.css_class, classes.group)}>
        {title && (
          <Box px={2} py={1}>
            <Typography variant="subtitle2" color="primary">
              {title}
            </Typography>
          </Box>
        )}
        <Grid container alignItems="center">
          {items.map(item => (
            <Grid
              item
              zeroMinWidth
              key={item.id}
              xs={columnSpan}
            >
              <Item
                dense
                item={item}
                classes={classes}
                onClick={onClick}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {!isLastGroup && (
        <Box my={1}>
          <Divider />
        </Box>
      )}
    </>
  );
}

Group.propTypes = {
  item: megaMenuGroupShape,
  classes: PropTypes.object.isRequired,
  isLastGroup: PropTypes.bool.isRequired,
  // Events
  onClick: PropTypes.func
};
Group.defaultProps = {
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  group: {
    maxWidth: 600
  },
  item: {
    minWidth: 150
  },
  itemBadge: {
    marginLeft: theme.spacing(1),
    fontSize: '0.65rem',
    height: 18
  },
  itemImageContainer: {
    marginRight: theme.spacing(2)
  },
  itemImage: {
    width: 70,
  }
}));

function mapBadgeIcon(icon) {
  switch (icon) {
    case 'plus':
      return <FiPlus />;
    case 'help':
      return <FiHelpCircle />;
    case 'loader':
      return <FiLoader />;
    case 'star':
      return <FiStar />;
    case 'tag':
      return <FiTag />;
    case 'sun':
      return <FiSun />;
    case 'zap':
      return <FiZap />;
    case 'battery':
      return <FiBatteryCharging />;
    default:
      return null;
  }
}
