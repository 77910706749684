import { HYDRATE, createWrapper } from 'next-redux-wrapper';

import { configureStore } from '@reduxjs/toolkit';
import getConfig from 'next/config';
import reducers from './reducers';

const { publicRuntimeConfig } = getConfig();

const makeStore = () => configureStore({
  reducer: (state = {}, action) => {
    switch (action.type) {
      case HYDRATE:
        return { ...state, ...action.payload };
      default:
        return reducers(state, action);
    }
  },
});

export const reduxWrapper = createWrapper(makeStore, {
  debug: publicRuntimeConfig.isDev,
});
export const withRedux = reduxWrapper.withRedux;
