import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export function resolveCdnUrl(relativePath) {
  if (publicRuntimeConfig.isDev) {
    return relativePath;
  }

  return publicRuntimeConfig.ASSET_PREFIX + relativePath;
}
