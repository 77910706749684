import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { resolveCdnUrl } from '../../lib/utils/cdn';

export default function Image(props) {
  const {
    className,
    src,
    alt,
    isFluid,
    isSquare,
    ...otherProps
  } = props;
  const classes = useStyles();

  let prefixedSrc = src;

  if (src && !src.startsWith('http') && !src.startsWith('//')) {
    prefixedSrc = resolveCdnUrl(src);
  }

  const sources = [
    { srcSet: prefixedSrc, type: 'image/jpeg' },
  ];
  const imgClasses = [
    classes.root,
    !isSquare ? classes.rootRounded : null,
    className,
  ];

  if (isFluid) {
    imgClasses.push(classes.fluid);
  }

  return (
    <picture>
      {sources.map(({ srcSet, type }) => (
        <source key={type} srcSet={srcSet} type={type} />
      ))}
      <img
        {...otherProps}
        className={clsx(imgClasses)}
        src={prefixedSrc}
        alt={alt}
        loading="lazy"
      />
    </picture>
  );
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  isFluid: PropTypes.bool,
  isSquare: PropTypes.bool,
  // isWebP: PropTypes.bool,
};
Image.defaultProps = {
  className: null,
  src: null,
  alt: null,
  isFluid: false,
  isSquare: false
  // isWebP: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  rootRounded: {
    borderRadius: theme.shape.borderRadius
  },
  fluid: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
}));
