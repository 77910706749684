import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import Container from '../../atoms/Container';
import DesktopMenuItem from './DesktopMenuItem';
import { FiLock } from 'react-icons/fi';
import LoginModal from '../LoginModal';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { navigationItemShape } from './propTypes';
import { useState } from 'react';

export default function DesktopMenu(props) {
  const { items, onClick } = props;
  const classes = useStyles();
  const [isLoginFormModalOpen, setLoginFormModalOpen] = useState(false);

  const handleToggleLoginFormModal = () => {
    setLoginFormModalOpen(!isLoginFormModalOpen);
  };

  return (
    <Container className={classes.root}>
      <List
        component="nav"
        disablePadding
        className={classes.container}
      >
        {items.map(item => (
          <DesktopMenuItem
            key={item.path}
            item={item}
            level={1}
            onClick={onClick}
          />
        ))}
        <ListItem
          button
          className={classes.button}
          onClick={handleToggleLoginFormModal}
        >
          <ListItemIcon>
            <FiLock />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'subtitle2',
            }}
          >
            Partner-Login
          </ListItemText>
        </ListItem>
      </List>
      <LoginModal
        isOpen={isLoginFormModalOpen}
        onClose={handleToggleLoginFormModal}
      />
    </Container>
  );
}

DesktopMenu.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func
};
DesktopMenu.defaultProps = {
  items: []
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.secondary.contrastText
  },
  button: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));
