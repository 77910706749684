import {
  List,
} from '@material-ui/core';
import MobileMenuItem from './MobileMenuItem';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { navigationItemShape } from './propTypes';

export default function MobileMenu(props) {
  const { items, onClick } = props;
  const classes = useStyles();

  return (
    <List
      component="nav"
      className={classes.root}
    >
      {items.map(item => (
        <MobileMenuItem
          key={item.path}
          item={item}
          level={1}
          onClick={onClick}
        />
      ))}
    </List>
  );
}

MobileMenu.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func
};

const useStyles = makeStyles(() => ({
  root: {
  }
}));
