import NativeLink from 'next/link';
import React from 'react';

export default function Link(props) {
  const linkProps = {
    ...props
  };

  /*if (linkProps.href === '#') {
    linkProps.href = {
      //pathname: '#'
    };
  }*/

  return (
    <NativeLink {...linkProps} />
  );
}

Link.propTypes = {
  ...NativeLink.propTypes
};
Link.defaultProps = {
  ...NativeLink.defaultProps,
  passHref: true,
  scroll: true
};
