import React from 'react';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

export default function TextInput(props) {
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
        className: clsx(props.InputProps?.className, {
          [classes.multiline]: Boolean(props.multiline)
        })
      }}
      { ...props }
    />
  );
}

TextInput.propTypes = {
  ...TextField.propTypes
};
TextInput.defaultProps = {
  ...TextField.defaultProps,
};

const useStyles = makeStyles((theme) => ({
  multiline: {
    paddingTop: 7
  }
}));
