import {
  AppBar,
  Box,
  Collapse,
  Container,
  Fade,
  Hidden,
  IconButton
} from '@material-ui/core';
import {
  FiMenu,
  FiX
} from 'react-icons/fi';
import { defaultBrandLogoSize, zIndices } from '../../theme';
import { useEffect, useState } from 'react';

import Backdrop from '../atoms/Backdrop';
import BrandLogo from '../molecules/BrandLogo';
import Image from '../atoms/Image';
import Link from '../atoms/Link';
import MainMenu from '../molecules/MainMenu';
import PageTopBar from './PageTopBar';
import PropTypes from 'prop-types';
import ProvenExpertGoogleStars from './ProvenExpertGoogleStars';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useInView } from 'react-intersection-observer';

const defaultHeightDesktop = 100; // Breadcrumbs height
const defaultHeightMobile = 70; // Breadcrumbs height
export const collapsedHeightDesktop = '70px';
export const collapsedHeightMobile = '50px';

export default function Header(props) {
  const { data, config, breadcrumbs } = props;
  const classes = useStyles();
  const [isMounted, setMounted] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [ref, inView] = useInView();

  const { navigationMain } = data;

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleToogleMenu = () => {
    const shouldBeOpen = !isMenuOpen;
    setMenuOpen(shouldBeOpen);

    if (shouldBeOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };
  const handleCloseMenu = () => {
    setMenuOpen(false);
    document.body.classList.remove('no-scroll');
  };

  const shouldCollapse = isMounted && !inView;

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.root, {
          [classes.offsetTop]: !shouldCollapse,
          [classes.offsetScrolled]: shouldCollapse,
          [classes.rootInverted]: isMenuOpen,
        })}
      >
        {config?.isTopBarEnabled && (
          <PageTopBar />
        )}
        <Backdrop className={classes.backdrop}>
          <Hidden mdUp>
            <div className={classes.ratingBar}>
              <Container>
                <ProvenExpertGoogleStars />
              </Container>
            </div>
          </Hidden>
          {/*<Collapse in={!shouldCollapse}>
            <Container>
              <Breadcrumbs data={breadcrumbs} />
            </Container>
        </Collapse>*/}
          <Container className={classes.container}>
            <div className={classes.brandLogoContainer}>
              <Link href="/">
                <a onClick={handleCloseMenu}>
                  <BrandLogo
                    className={classes.brandLogo}
                  />
                </a>
              </Link>
            </div>
            <div className={classes.space} />
            <Hidden smDown>
              <div className={classes.ratingBar}>
                <ProvenExpertGoogleStars />
              </div>
              <Link href="#">
                <a>
                  <Image
                    isFluid
                    src="/images/px-seal-1.png"
                    alt="Proven Expert"
                    className={clsx(
                      classes.carBrandLogo,
                      classes.carBrandLogoPxSeal
                    )}
                  />
                </a>
              </Link>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                aria-label="menu"
                onClick={handleToogleMenu}
              >
                {isMenuOpen ? <FiX /> : <FiMenu />}
              </IconButton>
            </Hidden>
          </Container>
          <Hidden smDown implementation="css">
            <Collapse in={!shouldCollapse}>
              <MainMenu
                desktop
                items={navigationMain}
                onClick={handleCloseMenu}
              />
            </Collapse>
          </Hidden>
        </Backdrop>
      </AppBar>
      <div
        ref={ref}
        className={classes.scrollingTarget}
        style={{ top: `${defaultHeightDesktop}px` }}
      />
      <div className={classes.pageInset} />
      <Fade
        in={isMenuOpen}
        timeout={400}
        className={classes.mobileMenuContainer}
        style={{
          transformOrigin: 'right top',
        }}
      >
        <Container>
          <Box py={5}>
            <MainMenu
              mobile
              items={navigationMain}
              onClick={handleCloseMenu}
            />
          </Box>
        </Container>
      </Fade>
    </>
  );
}

Header.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object)
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: zIndices.header,
    marginTop: '-1px', // iOS clipping bug top
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgba(204, 51, 51, 0.15)',
  },
  backdrop: {
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: theme.transitions.create('height', {
      duration: theme.transitions.duration.complex,
    }),
    '& a:not(:last-child), button:not(:last-child)': {
      marginRight: `${theme.spacing()}px`,
    },
  },
  brandLogoContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  brandLogo: {
    display: 'block',
    marginRight: theme.spacing(),
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  carBrandLogo: {
    marginRight: theme.spacing(2),
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  carBrandLogoImmoscout24Seal: {},
  carBrandLogoPxSeal: {},
  offsetTop: {
    '& $container': {
      height: `${defaultHeightMobile}px`,
    },
    '& $brandLogo': {
      width: 'auto',
      height: `${defaultBrandLogoSize * 0.85}px`,
    }
  },
  offsetScrolled: {
    '& $container': {
      height: collapsedHeightMobile,
    },
    '& $brandLogo': {
      height: '20px',
    },
  },
  scrollingTarget: {
    position: 'absolute',
    width: '100%',
    height: '1px',
  },
  pageInset: {
    //marginBottom: defaultHeight + PageTopBarHeight + MainMenuHeight,
  },
  space: {
    flexGrow: 1,
  },
  ratingBar: {
    backgroundColor: theme.palette.primary.main,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    '& div > div': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& a span': {
      textDecoration: 'none !important',
      color: theme.palette.primary.contrastText
    },
    '& #pe_rating': {
      fontSize: 13
    },
    '& .pe_u': {
      margin: '0 !important',
      lineHeight: '1em'
    },
    '& #pe_stars::before': {
      color: theme.palette.primary.contrastText
    },
    '& #pe_stars > span::before': {
      color: '#FFEB3B'
    }
  },
  [theme.breakpoints.up('md')]: {
    offsetTop: {
      '& $container': {
        height: `${defaultHeightDesktop}px`,
      },
      '& $brandLogo': {
        width: 'auto',
        height: `${defaultBrandLogoSize}px`,
      },
      '& $carBrandLogoPxSeal': {
        width: 100,
      },
      '& $carBrandLogoImmoscout24Seal': {
        width: 47,
      }
    },
    offsetScrolled: {
      '& $container': {
        height: collapsedHeightDesktop,
      },
      '& $brandLogo': {
        height: '20px',
      },
      '& $carBrandLogoPxSeal': {
        width: 70,
      },
      '& $carBrandLogoImmoscout24Seal': {
        width: 30,
      }
    },
    ratingBar: {
      background: 'none',
      marginRight: theme.spacing(3),
      '& a span': {
        textDecoration: 'none !important',
        color: theme.palette.primary.main,
        fontSize: `${theme.typography.subtitle2.fontSize} !important`,
        fontWeight: '700'
      },
      '& #pe_stars, & #pe_stars > span': {
        fontSize: '20px !important'
      }
    }
  },
  mobileMenuContainer: ({ colorScheme }) => ({
    position: 'fixed',
    display: 'flex',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flexDirection: 'column',
    paddingTop: `${defaultHeightMobile + theme.spacing()}px`,
    color: colorScheme === 'default'
      ? theme.palette.primary.contrastText
      : theme.palette.secondary.contrastText,
    background: colorScheme === 'default'
      ? theme.palette.primary.main
      : theme.palette.secondary.main,
    zIndex: zIndices.mobileMainMenu,
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    '& ul > li': {
      marginBottom: theme.spacing(2),
    },
  }),
  mobileMenuItem: {
    textTransform: 'uppercase',
  },
}));
