import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { getSession } from 'next-auth/react';
import getConfig from 'next/config';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';

const { publicRuntimeConfig } = getConfig();

let apolloClient = null;
const isServerSide = typeof window === 'undefined';

function createApolloClient() {
  return new ApolloClient({
    ssrMode: isServerSide,
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      setContext(async (_, { headers }) => {
        const session = await getSession();
        const newHeaders = { ...headers };

        if (session && session.accessToken) {
          newHeaders.authorization = `Bearer ${session.accessToken}`;
        }

        return {
          headers: newHeaders,
        };
      }),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) => {
            console.warn(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations, null, 2)}, Path: ${path}`
            );
          });
        }

        if (networkError) {
          console.warn(`[Network error]: ${networkError}`);
        }
      }),
      new HttpLink({
        uri: publicRuntimeConfig.GRAPHQL_ENDPOINT,
        credentials: 'same-origin',
        //useGETForQueries: true // CUrrently throws error
      })
    ])
  });
}

export function initializeApollo(initialState = null) {
  const client = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    client.cache.restore(initialState);
  }

  // For SSG and SSR always create a new Apollo Client
  if (isServerSide) {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

export function useApollo(initialState) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}
