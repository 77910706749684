import Box from '@material-ui/core/Box';
import Link from '../atoms/Link';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

export default function FooterMenu(props) {
  const { items } = props;
  const classes = useStyles();

  return items.map((item) => (
    <Box key={item.label} mb={2}>
      <Typography component="div" variant="body2" color="secondary">
        <Link href={item.href || '#'}>
          <a className={clsx(item.className, classes.link)} onClick={item.onClick}>
            {item.label}
          </a>
        </Link>
      </Typography>
    </Box>
  ));
}

FooterMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isExternal: PropTypes.bool,
  })),
};
FooterMenu.defaultProps = {
  items: [],
};

const useStyles = makeStyles((/* theme */) => ({
  link: {
    opacity: 0.75,
    transition: 'opacity 250ms ease-in-out',
    '&:hover, &:focus': {
      opacity: 1
    }
  }
}));
