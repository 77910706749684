import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { navigationItemShape } from './propTypes';

export const MainMenuHeight = 30;

export default function MainMenu(props) {
  const { desktop, mobile, items, onClick } = props;
  const classes = useStyles();

  const menuItems = items.filter(item => item.path !== '/' && item.display !== 'hidden');
  const desktopItems = menuItems.filter(m => m.display !== 'mobile');
  const mobileItems = menuItems.filter(m => m.display !== 'desktop');

  const handleItemClick = (e) => {
    if (onClick) onClick(e);
  };

  return (
    <div className={clsx(classes.root, {
      [classes.rootDesktop]: desktop,
      [classes.rootMobile]: mobile,
    })}>
      {desktop && (
        <DesktopMenu
          items={desktopItems}
          classes={classes}
          onClick={handleItemClick}
        />
      )}
      {mobile && (
        <MobileMenu
          items={mobileItems}
          classes={classes}
          onClick={handleItemClick}
        />
      )}
    </div>
  );
}

MainMenu.propTypes = {
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func
};
MainMenu.defaultProps = {
  desktop: false,
  mobile: false,
  items: []
};

const useStyles = makeStyles(() => ({
  root: {
  },
  rootMobile: {
  },
  rootDesktop: {
    borderTopColor: 'rgba(0, 0, 0, 0.05)',
    borderTopWidth: '0.1rem',
    borderTopStyle: 'solid'
  },
}));
