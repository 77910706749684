import * as yup from 'yup';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import marked from 'marked';
import useContentElement from '../../hooks/useContentElement';

export default function TypographyElement(props) {
  const { data, className, ...otherProps } = props;
  const classes = useStyles({
    color: props.color
  });
  const { elementData } = useContentElement(
    data,
    TypographyElement.dataSchema
  );

  // Set default values
  const semanticVariant = elementData.semantic_variant ?? 'div';
  let displayVariant = elementData.display_variant ?? 'body1';
  const textAlign = elementData.text_align ?? 'left';
  const htmlValue = elementData.value ? marked(elementData.value, {
    smartypants: true,
    breaks: true
  }) : '';
  let displayClassName = null;

  if (displayVariant === 'display1') {
    displayClassName = classes.display1;
  } else if (displayVariant === 'display2') {
    displayClassName = classes.display2;
  } else if (displayVariant === 'display3') {
    displayClassName = classes.display3;
  }

  return (
    <Typography
      {...otherProps}
      variant={displayVariant}
      component={semanticVariant}
      align={textAlign}
      dangerouslySetInnerHTML={{ __html: htmlValue }}
      className={clsx(classes.root, displayClassName, className)}
    >
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: props => ({
    hyphens: 'auto',
    '& a[href]': {
      color: theme.palette.primary.main,
      textDecoration: 'none !important',
      backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 25%, rgba(255,255,255,0) 0%)`,
      backgroundPosition: 'bottom',
      backgroundSize: '4px 1px',
      backgroundRepeat: 'repeat-x'
    },
    '& a[href]:hover, a[href]:focus': {
      color: theme.palette.primary.dark,
      backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark} 25%, ${theme.palette.primary.dark} 0%)`,
    }
  }),
  display1: theme.typography.display1,
  display2: theme.typography.display2,
  display3: theme.typography.display3
}));

const SEMANTIC_VARIANTS = [
  null,
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'div',
  'span'
];
const DISPLAY_VARIANTS = [
  null,
  'display1',
  'display2',
  'display3',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'overline',
  'srOnly',
  'inherit',
];
const TEXT_ALIGNMENTS = [
  null,
  'left',
  'center',
  'right',
  'justify'
];

TypographyElement.typeName = 'TypographyElement'; // Strapi element type
TypographyElement.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
    semantic_variant: PropTypes.oneOf(SEMANTIC_VARIANTS),
    display_variant: PropTypes.oneOf(DISPLAY_VARIANTS),
    text_align: PropTypes.oneOf(TEXT_ALIGNMENTS),
  }).isRequired,
  className: PropTypes.string,
  color: PropTypes.string
};
TypographyElement.defaultProps = {
  data: {
    semantic_variant: 'div',
    display_variant: 'body1'
  }
};
TypographyElement.dataSchema = yup.object().shape({
  value: yup.string().nullable(),
  semantic_variant: yup.string().nullable().oneOf(SEMANTIC_VARIANTS),
  display_variant: yup.string().nullable().oneOf(DISPLAY_VARIANTS),
  text_align: yup.string().nullable().oneOf(TEXT_ALIGNMENTS)
});
TypographyElement.graphQlSchema = `
id
value
semantic_variant
display_variant
text_align
`;
