import { Box, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { FiArrowDown, FiFacebook, FiInstagram, FiYoutube } from 'react-icons/fi';
import { makeStyles, useTheme } from '@material-ui/styles';

import BrandLogo from '../molecules/BrandLogo';
import Container from '../atoms/Container';
import { DateTime } from 'luxon';
import FooterMenu from '../molecules/FooterMenu';
import Link from '../atoms/Link';
import React from 'react';
import { TypographyElement } from '@plugins/next-cms-core';
import clsx from 'clsx';
import { useConsentManager } from '@lib/gdpr';

const basePadding = 80;
const gridBreakpoints = {
  xs: 12,
  sm: 6,
  md: 3,
  lg: 3,
};

export default function Footer({ data, config }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { changeConsent } = useConsentManager();

  const { pageBottomConfig, navigationFooter = [] } = data ?? {};
  const { global_info_text } = pageBottomConfig ?? {};
  const { bottom_info_text } = config ?? {};

  return (
    <div className={classes.root}>
      <Container>
        {(global_info_text || bottom_info_text) && (
          <Box className={clsx(classes.infoContainer, classes.textMuted)}>
            {bottom_info_text && (
              <TypographyElement
                data={{
                  display_variant: 'caption',
                  value: bottom_info_text
                }}
                paragraph
                color="inherit"
                component="p"
              />
            )}
            {global_info_text && (
              <TypographyElement
                data={{
                  display_variant: 'caption',
                  value: global_info_text
                }}
                paragraph
                color="inherit"
                component="p"
              />
            )}
          </Box>
        )}
        <Box mb={2}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={gridBreakpoints.xs}
              sm={gridBreakpoints.sm}
              md={gridBreakpoints.md}
              lg={gridBreakpoints.lg}
            >
              <BrandLogo
                className={classes.brandLogo}
                style="inverted"
                alt=""
              />
              {/*<Box mt={3}>
                <a
                  href="https://www.provenexpert.com/csb-schimmel-automobile-gmbh/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                  title="Kundenbewertungen &amp; Erfahrungen zu CSB Schimmel Automobile GmbH. Mehr Infos anzeigen."
                  target="_blank" rel="noreferrer">
                  <img
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    data-name="provenexpert"
                    data-src="https://images.provenexpert.com/2e/6e/66449c43267c6ff417912012d2b7/widget_square_200_de_0.png"
                    alt="Erfahrungen &amp; Bewertungen zu CSB Schimmel Automobile GmbH"
                    width="200"
                    height="200"
                    className="img-fluid"
                    loading="lazy"
                  />
                </a>
              </Box>
              <Box mt={1}>
                <Link href="https://www.facebook.com/csb.berlin/">
                  <IconButton
                    color="secondary"
                    component="a"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <FiFacebook />
                  </IconButton>
                </Link>
                <Link href="https://www.youtube.com/channel/UC2vfkFLsmJEsHUuYkFp7UHA">
                  <IconButton
                    color="secondary"
                    component="a"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <FiYoutube />
                  </IconButton>
                </Link>
                <Link href="https://www.instagram.com/schimmelautomobile/">
                  <IconButton
                    color="secondary"
                    component="a"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <FiInstagram />
                  </IconButton>
                </Link>
              </Box>*/}
            </Grid>
            <Grid
              item
              xs={gridBreakpoints.xs}
              sm={gridBreakpoints.sm}
              md={gridBreakpoints.md}
              lg={gridBreakpoints.lg}
            >
              <div className={classes.menuContainer}>
                <Box mt={1} mb={3}>
                  <Typography
                    variant="h6"
                    component="div"
                    color="secondary"
                    className={classes.menuTitle}
                  >
                    <FiArrowDown />
                    BauDarlehen24
                  </Typography>
                </Box>
                <Box>
                  <FooterMenu
                    items={[
                      {
                        label: 'Finanzierungsrechner',
                        href: '/finanzierungsrechner'
                      },
                      {
                        label: 'Zinsentwicklung',
                        href: '/zinsentwicklung'
                      },
                      {
                        label: 'News',
                        href: '/news'
                      },
                      {
                        label: 'Über uns',
                        href: '/ueber-uns'
                      },
                    ]}
                  />
                </Box>
              </div>
            </Grid>
            <Grid
              item
              xs={gridBreakpoints.xs}
              sm={gridBreakpoints.sm}
              md={gridBreakpoints.md}
              lg={gridBreakpoints.lg}
            >
              <div className={classes.menuContainer}>
                <Box mt={1} mb={3}>
                  <Typography
                    variant="h6"
                    component="div"
                    color="secondary"
                    className={classes.menuTitle}
                  >
                    <FiArrowDown />
                    Mehr
                  </Typography>
                </Box>
                <Box>
                  <FooterMenu
                    items={[
                      {
                        label: 'Kontakt',
                        href: '/kontakt'
                      },
                      {
                        label: 'Datenschutz',
                        href: '/datenschutz'
                      },
                      {
                        label: 'Cookie-Einstellungen',
                        onClick: changeConsent
                      },
                      {
                        label: 'Impressum',
                        href: '/impressum'
                      },
                    ]}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
        <div className={classes.separator} />
        <Box mt={3} className={classes.textMuted}>
          <Typography variant="caption" color="inherit" component="p">
            &copy; {DateTime.local().year} BauDarlehen24 Vermittlungsgesellschaft mbH. Alle Rechte vorbehalten.
          </Typography>
          <Grid container spacing={isMobile ? 0 : 4}>
            {navigationFooter.map(item => (
              <Grid key={item.uiRouterKey} item xs={12} md={'auto'}>
                <Link href={item.path}>
                  <Typography variant="caption" color="inherit" component="a">
                    {item.title}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

const SEPARATOR_STYLE = {
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderBottomColor: 'rgba(255,255,255,0.1)',
};
const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.contrastText,
    paddingTop: basePadding / 2,
    paddingBottom: basePadding * 2,
  },
  textMuted: {
    color: theme.palette.primary.contrastText,
    opacity: 0.65,
  },
  separator: {
    ...SEPARATOR_STYLE
  },
  infoContainer: {
    ...SEPARATOR_STYLE,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  brandLogo: {
    height: 25,
  },
  menuContainer: {
    paddingLeft: theme.spacing(1) + 20
  },
  menuTitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1)
    },
    transform: `translateX(-${theme.spacing(1) + 20}px)`
  }
}));
