import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { isServerSide } from './utils/environment';
import pick from 'lodash/pick';
import store from 'store';

export function setUtmCampaign(utm_source, utm_medium) {
  storeValue(utm_source, utm_medium);
}

export function getUtmCampaignIfAvailable() {
  return pick(store.get('campaign'), [
    'utm_source',
    'utm_medium'
  ]);
}

export default function checkForUtmCampaign() {
  if (isServerSide()) {
    return;
  }

  const parsedUrl = new URL(window.location.href);
  const utm_source = parsedUrl.searchParams.get('utm_source');
  const utm_medium = parsedUrl.searchParams.get('utm_medium');

  if (isNil(utm_source) || isNil(utm_medium) || isEmpty(utm_source) || isEmpty(utm_medium)) {
    return;
  }

  const campaign = getUtmCampaignIfAvailable();

  if (campaign && campaign.utm_source === utm_source
    && campaign.utm_medium === utm_medium) {
    return;
  }

  storeValue(utm_source, utm_medium);
}

function storeValue(utm_source, utm_medium) {
  const expiration = (new Date(new Date().setDate(new Date().getDate() + 7))).getTime();
  store.set('campaign', { utm_source, utm_medium }, expiration);
}
