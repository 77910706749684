import Image from '../atoms/Image';
import PropTypes from 'prop-types';
import React from 'react';
import { defaultBrandLogoSize } from '../../theme';

export default function BrandLogo(props) {
  // eslint-disable-next-line no-unused-vars
  const { className, style } = props;
  const src = colorSchemeImageMapping[style];

  return (
    <Image
      className={className}
      src={src}
      alt="Baudarlehen24"
      isSquare
    />
  );
}

BrandLogo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.oneOf(['default', 'inverted'])
};
BrandLogo.defaultProps = {
  className: null,
  size: defaultBrandLogoSize,
  style: 'default'
};

const colorSchemeImageMapping = {
  default: '/images/brand/brand-logo-default.svg',
  inverted: '/images/brand/brand-logo-inverted.svg',
};
