import * as yup from 'yup';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import useContentElement from '../../hooks/useContentElement';

export default function MediaElement(props) {
  const { data, isMediaSvg, ...otherProps } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    MediaElement.dataSchema
  );

  // Set default values
  const {
    file,
    meta_alt,
    object_position_x,
    object_position_y,
    scaling
  } = elementData;

  if (!file) {
    return null;
  }

  switch (file.mime) {
    case 'video/mp4':
      return (
        <MediaVideo
          file={file}
          alt={otherProps.alt ?? meta_alt}
          classes={classes}
          positionX={object_position_x}
          positionY={object_position_y}
          {...otherProps}
        />
      );
    default:
      return (
        <MediaImage
          file={file}
          alt={otherProps.alt ?? meta_alt}
          classes={classes}
          positionX={object_position_x}
          positionY={object_position_y}
          style={scaling && !isMediaSvg ? {
            width: SCALING_MAP[scaling ?? 'normal'],
          } : null}
          {...otherProps}
        />
      );
  }
}

function MediaImage(props) {
  const {
    file,
    alt,
    classes,
    isSquare,
    isFluid,
    isAbsoluteFill,
    positionX,
    positionY,
    className,
    ...otherProps
  } = props;
  const url = file.url ?? '';
  const style = { ...otherProps.style };

  const imgClasses = [
    classes.root,
    (!isSquare && !isAbsoluteFill) ? classes.rootRounded : null,
    className,
  ];

  if (isFluid) {
    imgClasses.push(classes.fluid);
  }

  if (isAbsoluteFill) {
    imgClasses.push(classes.absoluteFill);
  }

  if (positionX || positionY) {
    style.objectPosition = `${positionX ?? 'center'} ${positionY ?? 'center'}`.toLowerCase();
  }

  return (
    <img
      className={clsx(imgClasses)}
      src={url}
      alt={alt}
      style={style}
      loading="lazy"
      {...otherProps}
    />
  );
}

function MediaVideo(props) {
  const {
    file,
    alt,
    classes,
    isSquare,
    isFluid,
    isAbsoluteFill,
    positionX,
    positionY,
    className,
    ...otherProps
  } = props;
  const url = file.url ?? '';
  const style = { ...otherProps.style };

  const videoClasses = [
    classes.root,
    (!isSquare && !isAbsoluteFill) ? classes.rootRounded : null,
    className,
  ];

  if (isFluid) {
    videoClasses.push(classes.fluid);
  }

  if (isAbsoluteFill) {
    videoClasses.push(classes.absoluteFill);
  }

  if (positionX || positionY) {
    style.objectPosition = `${positionX ?? 'center'} ${positionY ?? 'center'}`.toLowerCase();
  }

  return (
    <video autoPlay playsInline muted loop className={clsx(videoClasses)}>
      <source src={url} type="video/mp4" />
      This browser does not support the HTML5 video element.
    </video>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    objectFit: 'cover'
  },
  rootRounded: {
    borderRadius: theme.shape.borderRadius
  },
  fluid: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }
}));

const SCALING_MAP = {
  tiny: '25%',
  small: '50%',
  smaller: '75%',
  normal: '100%',
  larger: '125%',
  large: '150%',
  big: '175%'
};

MediaElement.propTypes = {
  data: PropTypes.shape({
    file: PropTypes.shape({
      id: PropTypes.string.isRequired,
      formats: PropTypes.object,
      url: PropTypes.string.isRequired
    }).isRequired,
    caption: PropTypes.string,
    meta_alt: PropTypes.string,
    object_position_x: PropTypes.string,
    object_position_y: PropTypes.string,
    scaling: PropTypes.oneOf([null, ...Object.keys(SCALING_MAP)])
  }).isRequired,
  isFluid: PropTypes.bool,
  isSquare: PropTypes.bool,
  isMediaSvg: PropTypes.bool,
  isAbsoluteFill: PropTypes.bool
};
MediaElement.defaultProps = {
  data: {
    caption: '',
    meta_alt: '',
    scaling: 'normal'
  },
  isFluid: true
};
MediaElement.dataSchema = yup.object().shape({
  file: yup.object().shape({
    id: yup.number(),
    formats: yup.object().nullable(),
    url: yup.string()
  }),
  caption: yup.string().nullable(),
  meta_alt: yup.string().nullable(),
  object_position_x: yup.string().nullable(),
  object_position_y: yup.string().nullable(),
  scaling: yup.string().oneOf([null, ...Object.keys(SCALING_MAP)]).nullable()
});
MediaElement.graphQlSchema = `
file {
  id
  created_at
  name
  alternativeText
  caption
  width
  height
  formats
  hash
  ext
  mime
  size
  url
}
caption
meta_alt
object_position_x
object_position_y
scaling
`;
