import React, { Suspense, lazy } from 'react';

import FloatingActionBar from '../organisms/FloatingActionBar';
import Footer from '../organisms/Footer';
import Header from '../organisms/Header';
import PropTypes from 'prop-types';
import ProvenExpertContext from '../../context/ProvenExpertContext';
import ScrollToTopFab from '../molecules/ScrollToTopFab';
import { isClientSide } from '../../lib/utils/environment';
import { useRouter } from 'next/router';

const GdprConsent = lazy(() => import('../organisms/GdprConsent'));
const ProvenExpertBar = lazy(() => import('../organisms/ProvenExpertBar'));
const GlobalNotice = lazy(() => import('../organisms/GlobalNotice'));
const Tracking = lazy(() => import('../organisms/Tracking'));

export default function WebsiteContainer({ children, pageProps }) {
  const {
    appData,
    isSidebarDisabled,
    pageHeadConfig,
    pageBottomConfig,
    provenExpertGoogleStars
  } = pageProps;
  const {
    siteConfig,
    siteNotice,
    renderBreadcrumbPath
  } = appData;
  const router = useRouter();

  return (
    <ProvenExpertContext.Provider value={provenExpertGoogleStars}>
      <Header
        data={appData}
        config={pageHeadConfig}
        breadcrumbs={renderBreadcrumbPath}
      />
      {children}
      <Footer data={appData} config={pageBottomConfig} />
      {!router.pathname.startsWith('/consultant') && !isSidebarDisabled && (
        <FloatingActionBar data={siteConfig} />
      )}
      <ScrollToTopFab />
      {isClientSide() && (
        <>
          <Suspense fallback={<div />}>
            <GdprConsent />
          </Suspense>
          <Suspense fallback={<div />}>
            <ProvenExpertBar />
          </Suspense>
          <Suspense fallback={<div />}>
            <GlobalNotice data={siteNotice} />
          </Suspense>
          <Suspense fallback={<div />}>
            <Tracking />
          </Suspense>
        </>
      )}
    </ProvenExpertContext.Provider>
  );
}

WebsiteContainer.propTypes = {
  children: PropTypes.element.isRequired,
  pageProps: PropTypes.object
};
