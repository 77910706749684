import * as yup from 'yup';

import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import useContentElement from '../../hooks/useContentElement';

export default function LinkElement(props) {
  const { data, children, onClick, ...otherProps } = props;
  const { elementData } = useContentElement(
    data,
    LinkElement.dataSchema
  );

  const { is_new_window } = elementData;
  let url = elementData.resolvedUrl ?? '#';
  let rel = null;

  if (elementData.url && elementData.url.length > 0) {
    url = elementData.url;
  }

  if (url && url.startsWith('http')) {
    rel = 'noopener noreferrer';
  }

  return (
    <Link
      href={url}
      passHref={true}
      {...otherProps}
    >
      {React.cloneElement(children, {
        target: is_new_window ? '_blank' : undefined,
        rel,
        onClick
      })}
    </Link>
  );
}

LinkElement.typeName = 'LinkElement'; // Strapi element type
LinkElement.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string,
    resolvedUrl: PropTypes.string,
    is_new_window: PropTypes.bool
  }).isRequired,
  children: PropTypes.node.isRequired,
};
LinkElement.defaultProps = {
  data: {
    is_new_window: false
  }
};
LinkElement.dataSchema = yup.object().shape({
  url: yup.string().nullable(),
  resolvedUrl: yup.string().nullable(),
  is_new_window: yup.boolean().nullable()
});
LinkElement.graphQlSchema = `
id
url
resolvedUrl
is_new_window
`;

